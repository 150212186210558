import $ from 'jquery';

$(document).ready(function () {
  acfHeroBlock();
});

function acfHeroBlock() {
  $('.js-acf-hero-block').each(function () {});
  // addSvgArrow();
}
// function addSvgArrow() {
//   var svg = `
//     <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 388 459.4">
//     <defs>
//         <style>
//             .cls-1 {
//             fill: #fe001c;
//             opacity: 0;
//             animation-duration: 0.5s;
//             animation-fill-mode: forwards;
//             }
//
//             .path1 {
//             animation-name: appearLeftToRight1;
//             }
//
//             .path2 {
//             animation-name: appearLeftToRight2;
//             }
//
//             @keyframes appearLeftToRight1 {
//             0% {
//             clip-path: inset(0 100% 0 0);
//             opacity: 0;
//             }
//             100% {
//             clip-path: inset(0 0 0 0);
//             opacity: 1;
//             }
//             }
//
//             @keyframes appearLeftToRight2 {
//             0% {
//             clip-path: inset(0 100% 0 0);
//             opacity: 0;
//             }
//             100% {
//             clip-path: inset(0 0 0 0);
//             opacity: 1;
//             }
//             }
//         </style>
//     </defs>
//     <g>
//         <g id="Layer_1">
//             <path class="cls-1 path1" d="M124.7,201.5c-.4,14.5,1.5,27.7,2.4,41,.3,5,.6,10,.6,15,0,3.7-1.2,6.7-3.6,9.6-11.3,14-23.4,27.3-36.5,39.7-1.2,1.1-2.6,1.9-3.1,3.7l36.9,51.4c0,.5,1.1.7,2,1,20.6,9,40.2,19.9,59.9,30.6,3.4,1.8,3.8,4.4,3.8,7.4-.1,8.2-.4,16.3-.9,24.5-.7,11.1-1.4,22.2-.9,34-6.1-3.4-11.9-6.6-17.7-9.7-51.5-28.1-105.6-49.4-163-62-4.6-1-4.9-3.9-4.8-7.2.6-11.6,1.2-23.3,2.1-34.9.5-6.5.9-13,.4-19.5-.3-4.4,1-7.5,4.4-10.4,40-32.6,77.5-67.8,111.3-106.8,1.9-2.2,3.8-4.4,6.5-7.5h0Z"/>
//             <path class="cls-1 path2" d="M84.5,310.5C241.8,261.7,342.2,158.3,384.5,0c3.7,23.8,6.2,48.6-1.1,74.2-39.6,139.5-128,234-261.9,287.8,0,.5-36.3-42.9-36.9-51.4Z"/>
//         </g>
//     </g>
// </svg>
//
//     `;
//   $('.acf-hero-block__arrow').each(function () {
//     $(this).append(svg);
//   });
// }
