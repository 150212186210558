import $ from 'jquery';

$(document).ready(function () {
  acfExampleBlock();
});

function acfExampleBlock() {
  $('.js-acf-example-block').each(function () {});
}
/* Frame 43 */

/* Line 1 */
//
// position: absolute;
// width: 107px;
// height: 0px;
// left: 907px;
// top: 6234px;
//
// /* D91D35 */
// border: 3px solid #D0303A;
